.App {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

body {
  background-color: #121314;
}

.ShortActions {
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
  flex-wrap: wrap;
}